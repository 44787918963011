import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import siteLogo from '../../_images/rs_logo.png';
import { getIpd, userLogin, getClients, clientLogin, getClientToken, getUserFunctions, getIndustry } from '../../_actions/Login.actions';
import { updateUserActivity } from '../../_actions/Global.actions';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { GlobalROContext, ROErrorDialog, ROWarningDialog } from '../common';
import { getErrorMessage } from '../../_helpers/common';
import microsoftLogo from '../../_images/ms_signin_light.png';
import { ResetPasswordDialog } from './ResetPassword';

export const Login = () => {
    const navigate = useNavigate();

    const [idpSupport, setIdpSupport] = useState(false);
    const [idpLink, setIdpLink] = useState(null);
    const [step, setStep] = useState(0);
    const [clientList, setClientList] = useState([]);
    const [client, setClient] = useState(null);
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);

    const { setShowApiROErrorDialog } = useContext(GlobalROContext);

    const isDev = process.env.NODE_ENV === 'development';

    useEffect(() => {
        let user = sessionStorage.getItem('userInfo');
        let idpError = sessionStorage.getItem('idpError');
        if (idpError) {
            let idpErrorMessage = sessionStorage.getItem('idpErrorMessage');
            setShowApiROErrorDialog(JSON.parse(idpErrorMessage));
            getIpd().then(
                (res) => {
                    if (res.data && !isDev) {
                        setIdpSupport(true);
                        setIdpLink(res.data);
                    } else {
                        setIdpSupport(false);
                        setIdpLink(null);
                    }
                    setStep(1);
                },
                (err) => {
                    setIdpSupport(false);
                    setIdpLink(null);
                    setStep(1);
                },
            );
            sessionStorage.removeItem('idpError');
            sessionStorage.removeItem('idpErrorMessage');
        } else {
            if (user) {
                let userInfo = JSON.parse(user);
                if (userInfo.accessToken) {
                    getUserFunctions().then((res) => {
                        let functionIDs = [];
                        res.data.forEach((d) => functionIDs.push(parseInt(d.FunctionID, 10)));
                        sessionStorage.setItem('userFunctions', functionIDs.toString());
                    });
                    if (!userInfo.industry) {
                        getIndustry(userInfo.ClientID).then(
                            (res) => {
                                userInfo.industry = res.data.IndustryName;
                                sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
                                if (userInfo.industry === 'Public Works') {
                                    navigate('/map');
                                } else if (userInfo.industry === 'Newspaper') {
                                    window.location.href = '/clients/client_index.html';
                                }
                            },
                            (err) => {
                                setShowApiROErrorDialog(getErrorMessage(err));
                            },
                        );
                    } else {
                        if (userInfo.industry === 'Public Works') {
                            navigate('/map');
                        } else if (userInfo.industry === 'Newspaper') {
                            window.location.href = '/clients/client_index.html';
                        } else {
                            setShowApiROErrorDialog("Can't determine your insudtry. Please retry login.");
                        }
                    }
                } else if (userInfo.adminToken) {
                    getClients().then(
                        (res) => {
                            let list = [];
                            res.data.forEach((c) => {
                                list.push({
                                    label: c.Name,
                                    value: c.Id,
                                    industry: c.IndustryId,
                                });
                            });
                            setClientList(list);
                            if (localStorage.getItem('previousClient')) {
                                setClient(JSON.parse(localStorage.getItem('previousClient')));
                            }
                        },
                        (err) => {
                            console.log(err);
                            if (err && err.response && err.response.status === 401) {
                                sessionStorage.removeItem('userInfo');
                                setStep(1);
                            }
                        },
                    );
                    setStep(2);
                }
            } else {
                getIpd().then(
                    (res) => {
                        if (res.data && !isDev) {
                            setIdpSupport(true);
                            setIdpLink(res.data);
                        } else {
                            setIdpSupport(false);
                            setIdpLink(null);
                        }
                        setStep(1);
                    },
                    (err) => {
                        setIdpSupport(false);
                        setIdpLink(null);
                        setStep(1);
                    },
                );
            }
        }
    }, []);

    useEffect(() => {
        if (client) localStorage.setItem('previousClient', JSON.stringify(client));
    }, [client]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const userName = data.get('userName');
        const password = data.get('password');

        const isAdmin = window.location.hostname.includes('admin') || window.location.hostname.includes('localhost');
        if (isAdmin) {
            const data = {
                grant_type: 'password',
                userName: userName,
                password: password,
            };
            userLogin(data, true).then(
                (res) => {
                    const userInfo = {
                        adminToken: res.data.access_token,
                        FunctionID: res.data.page_functions,
                        ClientID: -1,
                        industry: '',
                        UserID: userName,
                        password: btoa(password),
                        userSession: Date.now() + '-' + Math.random().toString(36).substr(2, 9),
                    };
                    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
                    getClients().then((res) => {
                        let list = [];
                        res.data.forEach((c) => {
                            list.push({
                                label: c.Name,
                                value: c.Id,
                                industry: c.IndustryId,
                            });
                        });
                        setClientList(list);
                        if (localStorage.getItem('previousClient')) {
                            setClient(JSON.parse(localStorage.getItem('previousClient')));
                        }
                    });
                    setStep(2);
                },
                (err) => {
                    setShowApiROErrorDialog(getErrorMessage(err));
                },
            );
        } else {
            const data = {
                grant_type: 'password',
                userName: userName,
                password: password,
            };
            clientLogin(data).then(
                (res) => {
                    let userInfo = {};
                    userInfo.accessToken = res.data.access_token;
                    userInfo.ClientID = res.data.client_id;
                    userInfo.ClientName = res.data.client_name;
                    userInfo.FirstName = res.data.first_name;
                    userInfo.UserID = res.data.userName;
                    userInfo.email = res.data.email;
                    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
                    updateUserActivity({ ActionId: 1 }).then(
                        (result) => {
                            getUserFunctions().then((res) => {
                                let functionIDs = [];
                                res.data.forEach((d) => functionIDs.push(parseInt(d.FunctionID, 10)));
                                sessionStorage.setItem('userFunctions', functionIDs.toString());
                            });
                            getIndustry(userInfo.ClientID).then(
                                (res) => {
                                    userInfo.industry = res.data.IndustryName;
                                    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
                                    if (userInfo.industry === 'Public Works') {
                                        navigate('/map');
                                    } else if (userInfo.industry === 'Newspaper') {
                                        window.location.href = '/clients/client_index.html';
                                    } else {
                                        setShowApiROErrorDialog("Can't determine your insudtry. Please retry login.");
                                    }
                                },
                                (err) => {
                                    setShowApiROErrorDialog(getErrorMessage(err));
                                },
                            );
                        },
                        (error) => {
                            getUserFunctions().then((res) => {
                                let functionIDs = [];
                                res.data.forEach((d) => functionIDs.push(parseInt(d.FunctionID, 10)));
                                sessionStorage.setItem('userFunctions', functionIDs.toString());
                            });
                            getIndustry(userInfo.ClientID).then(
                                (res) => {
                                    userInfo.industry = res.data.IndustryName;
                                    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
                                    if (userInfo.industry === 'Public Works') {
                                        navigate('/map');
                                    } else if (userInfo.industry === 'Newspaper') {
                                        window.location.href = '/clients/client_index.html';
                                    } else {
                                        setShowApiROErrorDialog("Can't determine your insudtry. Please retry login.");
                                    }
                                },
                                (err) => {
                                    setShowApiROErrorDialog(getErrorMessage(err));
                                },
                            );
                        },
                    );
                },
                (err) => {
                    setShowApiROErrorDialog(getErrorMessage(err));
                },
            );
        }
    };

    const IdpSignInButton = () => {
        let logo;
        if (idpLink.includes('microsoft')) {
            logo = microsoftLogo;
        }
        if (logo) {
            return (
                <Box
                    component='section'
                    sx={{ m: 2, p: 2, border: '1px solid #ddd', borderRadius: 5 }}
                >
                    <IconButton onClick={idpLogin}>
                        <img src={logo} />
                    </IconButton>
                </Box>
            );
        } else {
            return (
                <Box
                    component='section'
                    sx={{ m: 2, p: 2, border: '1px solid #ddd', borderRadius: 5 }}
                >
                    <Button
                        fullWidth
                        variant='contained'
                        style={{ margin: '20px 0', backgroundImage: 'linear-gradient(to bottom,#5cb85c 0,#419641 100%)', width: '200px' }}
                        onClick={idpLogin}
                    >
                        Sign In
                    </Button>
                </Box>
            );
        }
    };

    const idpLogin = () => {
        if (idpLink) {
            window.location.replace(idpLink);
        }
    };

    const loginToClientPortal = () => {
        let user = sessionStorage.getItem('userInfo');
        if (user && client) {
            let userInfo = JSON.parse(user);
            getClientToken(client.value).then(
                (res) => {
                    userInfo.accessToken = res.data.access_token;
                    userInfo.ClientID = res.data.client_id;
                    userInfo.ClientName = res.data.client_name;
                    userInfo.FirstName = res.data.first_name;
                    userInfo.industry = client.industry === 2 ? 'Public Works' : 'Newspaper';
                    userInfo.email = res.data.email;
                    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
                    updateUserActivity({ ActionId: 1 }).then(
                        (response) => {
                            getUserFunctions().then((res) => {
                                let functionIDs = [];
                                res.data.forEach((d) => functionIDs.push(parseInt(d.FunctionID, 10)));
                                sessionStorage.setItem('userFunctions', functionIDs.toString());
                            });
                            if (client.industry === 2) {
                                navigate('/map');
                            } else {
                                window.location.href = '/clients/client_index.html';
                            }
                        },
                        (error) => {
                            getUserFunctions().then((res) => {
                                let functionIDs = [];
                                res.data.forEach((d) => functionIDs.push(parseInt(d.FunctionID, 10)));
                                sessionStorage.setItem('userFunctions', functionIDs.toString());
                            });
                            if (client.industry === 2) {
                                navigate('/map');
                            } else {
                                window.location.href = '/clients/client_index.html';
                            }
                        },
                    );
                },
                (err) => {
                    setShowApiROErrorDialog(getErrorMessage(err));
                },
            );
        }
    };

    const enterAdminPortal = () => {
        window.location.href = '/clients/admin_index.html';
    };

    return (
        <>
            <Container
                component='main'
                style={{ maxWidth: '400px' }}
            >
                <Box style={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box
                        component='img'
                        style={{ height: 89, width: 200, margin: '30px 0' }}
                        src={siteLogo}
                    />
                    {step === 0 && <CircularProgress style={{ margin: 100 }} />}
                    {!idpSupport && step === 1 && (
                        <Box
                            component='form'
                            onSubmit={(e) => handleSubmit(e)}
                            style={{ marginTop: 1 }}
                        >
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                autoFocus
                                autoComplete='userName'
                                label='User Name'
                                name='userName'
                                id={'LoginForm_TextField_UserName'}
                            />
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                type='password'
                                autoComplete='current-password'
                                label='Password'
                                name='password'
                                id={'LoginForm_TextField_Password'}
                            />
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                id={'LoginForm_Button_SignIn'}
                                style={{ margin: '20px 0', backgroundImage: 'linear-gradient(to bottom,#5cb85c 0,#419641 100%)' }}
                            >
                                Sign In
                            </Button>
                            <div style={{ margin: '10px 0 20px 0', textAlign: 'center', fontSize: '14px' }}>
                                Forgot your password?{' '}
                                <a
                                    onClick={() => setResetPasswordDialogOpen(true)}
                                    id={'LoginForm_Button_ForgetPassword'}
                                    style={{ color: '#f1c40f', cursor: 'pointer' }}
                                >
                                    Click Here
                                </a>
                            </div>
                            <ResetPasswordDialog
                                resetPasswordDialogOpen={resetPasswordDialogOpen}
                                setResetPasswordDialogOpen={setResetPasswordDialogOpen}
                            />
                        </Box>
                    )}
                    {idpSupport && step === 1 && (
                        <Box
                            component='div'
                            style={{ marginTop: 1 }}
                        >
                            <IdpSignInButton />
                        </Box>
                    )}
                    {step === 2 && (
                        <Box
                            component='div'
                            style={{ marginTop: 10, width: '100%' }}
                        >
                            <label style={{ fontSize: '24px', color: '#0088D8', fontWeight: 400, letterSpacing: '1px' }}>Pick a Client</label>

                            <hr style={{ height: 0, border: 0, borderTop: '1px solid #e4e6e8' }} />

                            <div style={{ width: '100%' }}>
                                <ReactSelect
                                    id='ClientSelect'
                                    options={clientList}
                                    value={client ? clientList.find((c) => c.value === client.value) : null}
                                    onChange={setClient}
                                />
                            </div>
                            <Button
                                fullWidth
                                variant='contained'
                                style={
                                    !client
                                        ? { margin: '20px 0' }
                                        : {
                                              margin: '20px 0',
                                              backgroundImage: 'linear-gradient(to bottom,#5cb85c 0,#419641 100%)',
                                              textTransform: 'none',
                                          }
                                }
                                disabled={!client}
                                onClick={() => loginToClientPortal()}
                            >
                                Continue
                            </Button>

                            <div style={{ textAlign: 'center', margin: 0, marginBottom: '10px' }}>
                                <hr
                                    style={{
                                        marginBottom: 0,
                                        position: 'relative',
                                        top: '19px',
                                        height: 0,
                                        border: 0,
                                        borderTop: '1px solid #e4e6e8',
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        padding: '7px 10px',
                                        color: '#6a737c',
                                        backgroundColor: 'white',
                                    }}
                                >
                                    OR
                                </div>
                            </div>

                            <Button
                                fullWidth
                                variant='contained'
                                style={{
                                    margin: '20px 0',
                                    backgroundImage: 'linear-gradient(to bottom,#5cb85c 0,#419641 100%)',
                                    textTransform: 'none',
                                }}
                                onClick={() => enterAdminPortal()}
                            >
                                Go to Administration Portal
                            </Button>
                        </Box>
                    )}
                </Box>
                <Copyright style={{ marginTop: 2, marginBottom: 4 }} />
            </Container>
            <ROErrorDialog />
            <ROWarningDialog />
        </>
    );
};

const Copyright = (props) => {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}
        >
            {'Copyright © '}
            <Link
                color='inherit'
                href='https://wwww.routesmart.com/'
            >
                RouteSmart Technologies, Inc.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};
