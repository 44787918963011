import { axiosDefault, axiosHeaders } from '../_helpers/auth-header';
import { config } from '../_helpers/config';

export const getWorkspaceList = () => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces?funcId=209';
    const headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getRouteList = (wsId, serviceDay) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    if (userInfo.industry !== 'Public Works') {
        serviceDay = 0;
    }
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'workspaces/' + wsId + '/routes?funcId=209&service_day=' + serviceDay;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const updateUserActivity = (data) => {
    const url = config.ROApi + 'useractivity';
    let userInfo = sessionStorage.getItem('userInfo');
    if (userInfo) userInfo = JSON.parse(userInfo);
    if (data && userInfo) data.UserSession = userInfo.userSession;
    const headers = { Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken };

    return axiosDefault.post(url, data, { headers });
};

export const checkFunction = (id) => {
    let userFunctions = sessionStorage.getItem('userFunctions');
    if (userFunctions && userFunctions.length > 0) return userFunctions.indexOf(id) > -1;
    else return true; //userFunctions are empty for admin users. Default to true when userFunctions are empty
};

export const getUserFunctions = async () => {
    let user = window.sessionStorage.getItem('userInfo');
    if (user) {
        let userInfo = JSON.parse(user);
        let accessToken = userInfo.accessToken;
        const url = config.ROApi + 'pagefunctions/AssignableFunctions';
        const headers = {
            Authorization: 'Bearer ' + accessToken,
        };
        let response = await axiosDefault.get(url, { headers });
        let functionIDs = [];
        response.data.forEach((d) => functionIDs.push(parseInt(d.FunctionID, 10)));
        return functionIDs;
    } else {
        return [];
    }
};

export const getWorkspaceStatus = () => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/statuses?funcId=209&enabledWsOnly=false';
    const headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const uploadWorkspace = (id, file) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + id + '/upload';

    const formData = new FormData();
    formData.append('File', file);

    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    return axiosDefault.post(url, formData, { headers });
};

export const refreshRoute = (id) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/' + id + '/refresh';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.post(url, null, { headers });
};

export const getHereVersion = () => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'here';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getSystemInfo = () => {
    const url = config.ROApi + 'systemInfo';
    return axiosDefault.get(url);
};

export const checkSystemStatus = () => {
    let clientName = '';
    if (sessionStorage['userInfo']) {
        let userInfo = JSON.parse(sessionStorage['userInfo']);
        if (userInfo.ClientName) clientName = '?client=' + userInfo.ClientName;
    }
    const url = config.ROApi + 'systeminfo/status' + clientName;
    return axiosDefault.get(url);
};

export const checkDisableSandbox = (workspace, project) => {
    const url = `${config.ROApi}workspaces/${workspace}/sandboxes/${project}/disabled`;
    return axiosDefault.get(url, axiosHeaders());
};

export const checkUsePickupDeliveryCapacity = (workspace) => {
    const url = `${config.ROApi}workspaces/${workspace}/trucks/UsePickupDeliveryCapacity`;
    return axiosDefault.get(url, axiosHeaders());
};

export const checkContainerDeliverySetting = (workspace) => {
    const url = `${config.ROApi}workspaces/${workspace}/ContainerDeliveryAvail`;
    return axiosDefault.get(url, axiosHeaders());
};

export const checkCurrencySetting = (workspace) => {
    const url = `${config.ROApi}workspaces/${workspace}/currency`;
    return axiosDefault.get(url, axiosHeaders());
};
