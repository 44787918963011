import React from 'react';
import ReactSelect from 'react-select';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import OutlinedInput from '@mui/material/OutlinedInput';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { defaultServiceDayList, dailyRouteTimeOptions, dailyWorkloadOptions } from '../../_helpers/common';
import { FloatInput } from '../Manage/ManageController';

export const SandboxSolverDialog = (props) => {
    const {
        workspace,
        sandboxSolverDialogOpen,
        solverSettings,
        routePlanList,
        serviceDay,
        updateSolverSettings,
        handleSandboxSolverDialogClose,
        routeViewTabCategorization,
    } = props;

    const allDaysScenarioOptions = [
        { label: 'Create Service Days', value: 'CreateServiceDays' },
        { label: 'Create Routes', value: 'Create' },
        { label: 'Sequence Routes', value: 'Sequence' },
        { label: 'Assign Service Days & Routes', value: 'AssignServiceDays' },
    ];
    const scenarioOptions = [
        { label: 'Create Routes', value: 'Create' },
        { label: 'Sequence Routes', value: 'Sequence' },
    ];
    const mdrAllDaysScenarioOptions = [
        { label: 'Create Truck Territories', value: 'CreateRouteTerritories' },
        { label: 'Balance Service Days', value: 'BalanceServiceDays' },
        { label: 'Sequence Routes', value: 'Sequence' },
        { label: 'Assign Truck Territories', value: 'AssignTruckTerritories' },
    ];
    const methodOptions = [
        { label: 'Time', value: 'Time' },
        { label: 'Number', value: 'Number' },
        { label: 'Full Fleet', value: 'Full' },
    ];
    const zonesDefaultOptions = [
        { label: 'Off', value: 'Off' },
        { label: 'Sub-route', value: 'Sectors' },
        { label: 'Routes', value: 'Routes' },
    ];
    const zonesNumberOptions = [
        { label: 'Off', value: 'Off' },
        { label: 'Sub-route', value: 'Sectors' },
    ];
    const zonesSequenceOptions = [
        { label: 'Off', value: 'Off' },
        { label: 'Routes', value: 'Routes' },
    ];
    const balanceOnOptions = [
        { label: 'Time', value: 'Time' },
        { label: 'Load', value: 'Load' },
        { label: 'Lifts', value: 'Lifts' },
    ];
    const fromCheck = () => {
        if (!solverSettings.scenario) {
            return true;
        } else if (solverSettings.scenario === 'Create') {
            if (solverSettings.serviceDay !== 0) {
                if (!solverSettings.method) {
                    return true;
                } else if (solverSettings.method === 'Number') {
                    if (!solverSettings.number || !solverSettings.zones || !solverSettings.routePlan) {
                        return true;
                    }
                } else {
                    if (!solverSettings.zones || !solverSettings.routePlan) {
                        return true;
                    }
                }
            }
        } else if (solverSettings.scenario === 'CreateRouteTerritories') {
            if (solverSettings.method === 'Number') {
                if (!solverSettings.number || !solverSettings.zones || !solverSettings.routePlan) {
                    return true;
                }
            }
        } else if (solverSettings.scenario === 'Sequence') {
            if (solverSettings.serviceDay !== 0) {
                if (!solverSettings.zones || !solverSettings.routePlan) {
                    return true;
                }
            }
        }
        return false;
    };

    const checkRouteCount = () => {
        const plan = routePlanList.find((s) => s.value === solverSettings.routePlan);
        if (plan) {
            if (plan.RouteCount < 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    const checkAllowAnchorRoute = () => {
        if (serviceDay.value !== 0) {
            return props.routeViewTableData['R'].length === props.routeViewTableData['R'].filter((r) => r.Id === '0').length; //need adjustment here
        } else {
            if (routeViewTabCategorization.value.startsWith('R')) {
                return (
                    props.routeViewTableData[routeViewTabCategorization.value].length ===
                    props.routeViewTableData[routeViewTabCategorization.value].filter((r) => r.Id === '0').length
                ); //need adjustment here
            } else {
                let hasNonZero = false;
                props.routeViewTableData[routeViewTabCategorization.value].forEach((s) => {
                    s.subRows.forEach((ss) => {
                        if (ss.Id !== '0') {
                            hasNonZero = true;
                        }
                    });
                });
                return !hasNonZero;
            }
        }
    };

    return (
        <Dialog
            open={sandboxSolverDialogOpen}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>Run Solver</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    alignItems={'baseline'}
                >
                    <Grid
                        item
                        xs={2}
                    >
                        Scenario
                    </Grid>
                    <Grid
                        item
                        xs={8}
                    >
                        <ReactSelect
                            className='basic-single'
                            classNamePrefix='select'
                            isClearable={false}
                            isSearchable={false}
                            value={
                                serviceDay.value === 0 && workspace.type !== 'Roll-off'
                                    ? sessionStorage.getItem('mdr') === 'true'
                                        ? mdrAllDaysScenarioOptions.find((s) => s.value === solverSettings.scenario)
                                        : allDaysScenarioOptions.find((s) => s.value === solverSettings.scenario)
                                    : scenarioOptions.find((s) => s.value === solverSettings.scenario)
                            }
                            onChange={(e) => updateSolverSettings(e, 'scenario')}
                            options={
                                serviceDay.value === 0 && workspace.type !== 'Roll-off'
                                    ? sessionStorage.getItem('mdr') === 'true'
                                        ? mdrAllDaysScenarioOptions
                                        : allDaysScenarioOptions
                                    : scenarioOptions
                            }
                            menuPortalTarget={document.body}
                            styles={{
                                option: (provided, state) => ({
                                    ...provided,
                                    padding: 5,
                                }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                        />
                    </Grid>
                </Grid>
                {solverSettings.scenario === 'CreateServiceDays' && (
                    <>
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            />
                            <Grid
                                item
                                xs={8}
                                justifyContent='center'
                            >
                                <FormControlLabel
                                    label={<span style={{ marginLeft: '10px' }}>Anchor with current service days</span>}
                                    style={{ fontSize: 18, marginLeft: '2px', marginRight: '10px' }}
                                    control={
                                        <Checkbox
                                            name='anchorWithServiceDay'
                                            checked={solverSettings.anchorWithServiceDay}
                                            onChange={(e) => updateSolverSettings(e, 'anchorWithServiceDay')}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            />
                            <Grid
                                item
                                xs={3}
                            >
                                Number of Days
                            </Grid>
                            <Grid
                                item
                                xs={5}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={false}
                                    value={[...Array(7).keys()]
                                        .map((i) => ({ label: (i + 1).toString(), value: (i + 1).toString() }))
                                        .find((s) => s.value === solverSettings.numberOfDays)}
                                    onChange={(e) => updateSolverSettings(e, 'numberOfDays')}
                                    options={[...Array(7).keys()].map((i) => ({ label: (i + 1).toString(), value: (i + 1).toString() }))}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            />
                            <Grid
                                item
                                xs={3}
                            >
                                Balance on
                            </Grid>
                            <Grid
                                item
                                xs={5}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={false}
                                    value={balanceOnOptions.find((s) => s.value === solverSettings.balanceOn)}
                                    onChange={(e) => updateSolverSettings(e, 'balanceOn')}
                                    options={balanceOnOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            />
                            <Grid
                                item
                                xs={3}
                            >
                                Truck Capacity
                            </Grid>
                            <Grid
                                item
                                xs={5}
                            >
                                <FloatInput
                                    placeholder='0.00'
                                    type='text'
                                    id='truckCapacity'
                                    name='truckCapacity'
                                    value={solverSettings.truckCapacity}
                                    onChange={(e) => updateSolverSettings(e, 'truckCapacity')}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                {solverSettings.scenario === 'Create' && (
                    <>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            >
                                Service Day
                            </Grid>
                            <Grid
                                item
                                xs={8}
                            >
                                <TextField
                                    disabled
                                    size='small'
                                    fullWidth
                                    id='outlined-disabled'
                                    label=''
                                    value={defaultServiceDayList.find((s) => s.value === solverSettings.serviceDay)?.label}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            >
                                Route Plan
                            </Grid>
                            <Grid
                                item
                                xs={8}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={false}
                                    isDisabled={solverSettings.serviceDay === 0}
                                    value={
                                        solverSettings.serviceDay === 0
                                            ? [{ label: 'Use Scheduled', value: 0 }]
                                            : routePlanList.find((s) => s.value === solverSettings.routePlan)
                                    }
                                    onChange={(e) => updateSolverSettings(e, 'routePlan')}
                                    options={solverSettings.serviceDay === 0 ? [{ label: 'Use Scheduled', value: 0 }] : routePlanList}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        {solverSettings.serviceDay !== 0 && (
                            <>
                                <Grid
                                    container
                                    alignItems={'baseline'}
                                >
                                    <Grid
                                        item
                                        xs={2}
                                    >
                                        Method
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                    >
                                        <ReactSelect
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isClearable={false}
                                            isSearchable={false}
                                            value={
                                                checkRouteCount()
                                                    ? methodOptions.slice(0, -1).find((s) => s.value === solverSettings.method)
                                                    : methodOptions.find((s) => s.value === solverSettings.method)
                                            }
                                            onChange={(e) => updateSolverSettings(e, 'method')}
                                            options={checkRouteCount() ? methodOptions.slice(0, -1) : methodOptions}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    padding: 5,
                                                }),
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            }}
                                        />
                                    </Grid>
                                    {solverSettings.method === 'Number' && (
                                        <Grid
                                            item
                                            xs={2}
                                            style={{ paddingLeft: '5px' }}
                                        >
                                            <OutlinedInput
                                                value={solverSettings.number}
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { padding: 5 } }}
                                                onChange={(e) => updateSolverSettings(e, 'number')}
                                                placeholder={'Number'}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <br />
                            </>
                        )}
                        {solverSettings.serviceDay !== 0 && (
                            <Grid
                                container
                                alignItems={'baseline'}
                            >
                                <Grid
                                    item
                                    xs={2}
                                >
                                    Zones
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                >
                                    <ReactSelect
                                        className='basic-single'
                                        classNamePrefix='select'
                                        isClearable={false}
                                        isSearchable={false}
                                        isDisabled={checkRouteCount()}
                                        value={
                                            solverSettings.scenario === 'Create' && solverSettings.method === 'Number'
                                                ? zonesNumberOptions.find((s) => s.value === solverSettings.zones)
                                                : zonesDefaultOptions.find((s) => s.value === solverSettings.zones)
                                        }
                                        onChange={(e) => updateSolverSettings(e, 'zones')}
                                        options={
                                            solverSettings.scenario === 'Create' && solverSettings.method === 'Number'
                                                ? zonesNumberOptions
                                                : zonesDefaultOptions
                                        }
                                        menuPortalTarget={document.body}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                padding: 5,
                                            }),
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {solverSettings.serviceDay !== 0 && <br />}
                        {
                            <Grid
                                container
                                alignItems={'baseline'}
                            >
                                <Grid
                                    item
                                    xs={2}
                                />
                                <Grid
                                    item
                                    xs={8}
                                    justifyContent='center'
                                >
                                    <FormControlLabel
                                        label={<span style={{ marginLeft: '10px' }}>Anchor with current routes</span>}
                                        style={{ fontSize: 18, marginLeft: '2px', marginRight: '10px' }}
                                        control={
                                            <Checkbox
                                                name='anchorWithRoute'
                                                disabled={solverSettings['zones'] === 'Routes' || checkAllowAnchorRoute()}
                                                checked={solverSettings.anchorWithRoute}
                                                onChange={(e) => updateSolverSettings(e, 'anchorWithRoute')}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        }
                    </>
                )}
                {solverSettings.scenario === 'Sequence' && (
                    <>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            >
                                Service Day
                            </Grid>
                            <Grid
                                item
                                xs={8}
                            >
                                <TextField
                                    disabled
                                    size='small'
                                    fullWidth
                                    id='outlined-disabled'
                                    label=''
                                    value={defaultServiceDayList.find((s) => s.value === solverSettings.serviceDay)?.label}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            >
                                Route Plan
                            </Grid>
                            <Grid
                                item
                                xs={8}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={false}
                                    isDisabled={solverSettings.serviceDay === 0}
                                    value={
                                        solverSettings.serviceDay === 0
                                            ? [{ label: 'Use Scheduled', value: 0 }]
                                            : routePlanList.find((s) => s.value === solverSettings.routePlan)
                                    }
                                    onChange={(e) => updateSolverSettings(e, 'routePlan')}
                                    options={solverSettings.serviceDay === 0 ? [{ label: 'Use Scheduled', value: 0 }] : routePlanList}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        {solverSettings.serviceDay !== 0 && (
                            <Grid
                                container
                                alignItems={'baseline'}
                            >
                                <Grid
                                    item
                                    xs={2}
                                >
                                    Zones
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                >
                                    <ReactSelect
                                        className='basic-single'
                                        classNamePrefix='select'
                                        isClearable={false}
                                        isSearchable={false}
                                        value={zonesSequenceOptions.find((s) => s.value === solverSettings.zones)}
                                        onChange={(e) => updateSolverSettings(e, 'zones')}
                                        options={zonesSequenceOptions}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                padding: 5,
                                            }),
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
                {solverSettings.scenario === 'BalanceServiceDays' && (
                    <>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={3}
                            >
                                Daily Route Time
                            </Grid>
                            <Grid
                                item
                                xs={7}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={true}
                                    isDisabled={false}
                                    value={dailyRouteTimeOptions.find((s) => s.value === solverSettings.dailyRouteTime)}
                                    onChange={(e) => updateSolverSettings(e, 'dailyRouteTime')}
                                    options={dailyRouteTimeOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={3}
                            >
                                Daily Workload
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={1}
                            />
                            <Grid
                                item
                                xs={1}
                            >
                                Mon
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={true}
                                    isDisabled={false}
                                    value={dailyWorkloadOptions.find((s) => s.value === solverSettings.monLoad)}
                                    onChange={(e) => updateSolverSettings(e, 'monLoad')}
                                    options={dailyWorkloadOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                            />
                            <Grid
                                item
                                xs={1}
                            >
                                Thur
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={true}
                                    isDisabled={false}
                                    value={dailyWorkloadOptions.find((s) => s.value === solverSettings.thurLoad)}
                                    onChange={(e) => updateSolverSettings(e, 'thurLoad')}
                                    options={dailyWorkloadOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={1}
                            />
                            <Grid
                                item
                                xs={1}
                            >
                                Tues
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={true}
                                    isDisabled={false}
                                    value={dailyWorkloadOptions.find((s) => s.value === solverSettings.tuesLoad)}
                                    onChange={(e) => updateSolverSettings(e, 'tuesLoad')}
                                    options={dailyWorkloadOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                            />
                            <Grid
                                item
                                xs={1}
                            >
                                Fri
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={true}
                                    isDisabled={false}
                                    value={dailyWorkloadOptions.find((s) => s.value === solverSettings.friLoad)}
                                    onChange={(e) => updateSolverSettings(e, 'friLoad')}
                                    options={dailyWorkloadOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={1}
                            />
                            <Grid
                                item
                                xs={1}
                            >
                                Wed
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={true}
                                    isDisabled={false}
                                    value={dailyWorkloadOptions.find((s) => s.value === solverSettings.wedLoad)}
                                    onChange={(e) => updateSolverSettings(e, 'wedLoad')}
                                    options={dailyWorkloadOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                            />
                            <Grid
                                item
                                xs={1}
                            >
                                Sat
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={true}
                                    isSearchable={true}
                                    isDisabled={false}
                                    value={dailyWorkloadOptions.find((s) => s.value === solverSettings.satLoad)}
                                    onChange={(e) => updateSolverSettings(e, 'satLoad')}
                                    options={dailyWorkloadOptions}
                                    placeholder={'Locked'}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        valueContainer: (baseStyles, state) => ({
                                            ...baseStyles,
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                {solverSettings.scenario === 'CreateRouteTerritories' && (
                    <>
                        <br />
                        <Grid
                            container
                            alignItems={'center'}
                        >
                            <Grid
                                item
                                xs={2}
                            >
                                Service Day
                            </Grid>
                            <Grid
                                item
                                xs={8}
                            >
                                <TextField
                                    disabled
                                    size='small'
                                    fullWidth
                                    id='outlined-disabled'
                                    label=''
                                    value={defaultServiceDayList.find((s) => s.value === solverSettings.serviceDay)?.label}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            >
                                Route Plan
                            </Grid>
                            <Grid
                                item
                                xs={8}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={false}
                                    isDisabled={false}
                                    value={routePlanList.find((s) => s.value === solverSettings.routePlan)}
                                    onChange={(e) => updateSolverSettings(e, 'routePlan')}
                                    options={routePlanList}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            >
                                Method
                            </Grid>
                            <Grid
                                item
                                xs={solverSettings.method === 'Number' ? 6 : 8}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={false}
                                    value={
                                        checkRouteCount()
                                            ? methodOptions.slice(0, -1).find((s) => s.value === solverSettings.method)
                                            : methodOptions.find((s) => s.value === solverSettings.method)
                                    }
                                    onChange={(e) => updateSolverSettings(e, 'method')}
                                    options={checkRouteCount() ? methodOptions.slice(0, -1) : methodOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                            {solverSettings.method === 'Number' && (
                                <Grid
                                    item
                                    xs={2}
                                    style={{ paddingLeft: '5px' }}
                                >
                                    <OutlinedInput
                                        value={solverSettings.number}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { padding: 5 } }}
                                        onChange={(e) => updateSolverSettings(e, 'number')}
                                        placeholder={'Number'}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <br />
                        {(solverSettings.method === 'Number' || solverSettings.method === 'Full') && (
                            <>
                                <Grid
                                    container
                                    alignItems={'baseline'}
                                >
                                    <Grid
                                        item
                                        xs={2}
                                    />
                                    <Grid
                                        item
                                        xs={3}
                                    >
                                        Balance on
                                    </Grid>
                                    <Grid
                                        item
                                        xs={5}
                                    >
                                        <ReactSelect
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isClearable={false}
                                            isSearchable={false}
                                            value={balanceOnOptions.slice(0, -1).find((s) => s.value === solverSettings.balanceOn)}
                                            onChange={(e) => updateSolverSettings(e, 'balanceOn')}
                                            options={balanceOnOptions.slice(0, -1)}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    padding: 5,
                                                }),
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                            </>
                        )}
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                item
                                xs={2}
                            >
                                Zones
                            </Grid>
                            <Grid
                                item
                                xs={8}
                            >
                                <ReactSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={false}
                                    isDisabled={checkRouteCount()}
                                    value={
                                        solverSettings.method === 'Number'
                                            ? zonesNumberOptions.find((s) => s.value === solverSettings.zones)
                                            : zonesDefaultOptions.find((s) => s.value === solverSettings.zones)
                                    }
                                    onChange={(e) => updateSolverSettings(e, 'zones')}
                                    options={solverSettings.method === 'Number' ? zonesNumberOptions : zonesDefaultOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            padding: 5,
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems={'baseline'}
                        >
                            <Grid
                                container
                                alignItems={'baseline'}
                            >
                                <Grid
                                    item
                                    xs={2}
                                />
                                <Grid
                                    item
                                    xs={8}
                                    justifyContent='center'
                                >
                                    <FormControlLabel
                                        label={<span style={{ marginLeft: '10px' }}>Anchor with current truck territories</span>}
                                        style={{ fontSize: 18, marginLeft: '2px', marginRight: '10px' }}
                                        control={
                                            <Checkbox
                                                name='anchorWithRoute'
                                                disabled={solverSettings['zones'] === 'Routes'}
                                                checked={!!solverSettings.anchorWithRoute}
                                                onChange={(e) => updateSolverSettings(e, 'anchorWithRoute')}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleSandboxSolverDialogClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={fromCheck() ? { backgroundColor: '#fff' } : { backgroundColor: '#16a8a6', color: '#fff' }}
                    disabled={fromCheck()}
                    onClick={() => handleSandboxSolverDialogClose('RUN')}
                >
                    Run
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const SandboxUpdateRouteStatsDialog = (props) => {
    const {
        sandboxUpdateRouteStatsDialogOpen,
        routeStatsSettings,
        serviceDay,
        updateRotueStatsSettings,
        handleSandboxUpdateRouteStatsDialogClose,
        routePlanList,
    } = props;

    return (
        <Dialog
            open={sandboxUpdateRouteStatsDialogOpen}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitle>Update Route Statistics</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    alignItems={'center'}
                >
                    <Grid
                        item
                        xs={3}
                    >
                        Service Day
                    </Grid>
                    <Grid
                        item
                        xs={8}
                    >
                        <TextField
                            disabled
                            size='small'
                            fullWidth
                            id='outlined-disabled'
                            label=''
                            value={defaultServiceDayList.find((s) => s.value === routeStatsSettings.serviceDay)?.label}
                        />
                    </Grid>
                </Grid>
                <br />
                <Grid
                    container
                    alignItems={'baseline'}
                >
                    <Grid
                        item
                        xs={3}
                    >
                        Route Plan
                    </Grid>
                    <Grid
                        item
                        xs={8}
                    >
                        <ReactSelect
                            className='basic-single'
                            classNamePrefix='select'
                            isClearable={false}
                            isSearchable={false}
                            isDisabled={routeStatsSettings.serviceDay == '0'}
                            value={
                                routeStatsSettings.serviceDay == '0'
                                    ? { label: 'Use scheduled', value: 0 }
                                    : routePlanList.find((s) => s.value === routeStatsSettings.routePlan)
                            }
                            onChange={(e) => updateRotueStatsSettings(e, 'routePlan')}
                            options={routeStatsSettings.serviceDay == '0' ? [{ label: 'Use scheduled', value: 0 }] : routePlanList}
                            menuPortalTarget={document.body}
                            styles={{
                                option: (provided, state) => ({
                                    ...provided,
                                    padding: 5,
                                }),
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                        />
                    </Grid>
                </Grid>
                <br />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleSandboxUpdateRouteStatsDialogClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleSandboxUpdateRouteStatsDialogClose('SAVE')}
                >
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};
