import React, { useState, createContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useIdleTimer } from 'react-idle-timer';
import { components } from 'react-select';
import Draggable from 'react-draggable';
import { serviceDayList } from '../../_helpers/common';

export const GlobalROContext = createContext();

export const GlobalROProvider = (props) => {
    const [hereVersion, setHereVersion] = useState('v3');

    const [workspace, setWorkspace] = useState(null);
    const [serviceDay, setServiceDay] = React.useState({
        label: serviceDayList[0].label,
        value: 0,
    });
    const [week, setWeek] = React.useState(null);
    const [frequency, setFrequency] = React.useState(null);
    const [routePlanId, setRoutePlanId] = React.useState(null);
    const [servicePattern, setServicePattern] = React.useState([]);
    const [project, setProject] = React.useState(null);
    const [projectObject, setProjectObject] = React.useState(null);
    const [siteMode, setSiteMode] = React.useState('DISPATCHER');

    const [servicePatternView, setServicePatternView] = React.useState(false);
    const [runningJob, setRunningJob] = React.useState(false);
    const [globalLoading, setGlobalLoading] = React.useState(false);

    const [routingProjects, setRoutingProjects] = React.useState([]);
    const [routingProjectScenarios, setRoutingProjectScenarios] = React.useState([]);
    const [workspaceStatus, setWorkspaceStatus] = React.useState([]);

    const [refreshProject, setRefreshProject] = React.useState(false);
    const [refreshScenario, setRefreshScenario] = React.useState(false);
    const [refreshRoutePlan, setRefreshRoutePlan] = React.useState(false);
    const [refreshWorkspaceStatus, setRefreshWorkspaceStatus] = React.useState(true);

    const [showWorkspaceList, setShowWorkspaceList] = useState(true);
    const [showServiceDayList, setShowServiceDayList] = React.useState(true);
    const [showRoutePlanList, setShowRoutePlanList] = React.useState(false);
    const [showWeekList, setShowWeekList] = React.useState(true);
    const [showFrequencyList, setShowFrequencyList] = React.useState(true);
    const [showServicePatternList, setShowServicePatternList] = React.useState(true);
    const [showDownloadWorkspace, setShowDownloadWorkspace] = React.useState(false);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [showApiROErrorDialog, setShowApiROErrorDialog] = React.useState(''); //if empty string this is turned off. Non-empty string will turn the dialogs and show the
    const [showROWarningDialog, setShowROWarningDialog] = React.useState('');
    const [showContainerDelivery, setShowContainerDelivery] = useState(false);
    const [containerDeliveryEnabled, setContainerDeliveryEnabled] = useState(false);

    const [currencySetting, setCurrencySetting] = useState('USD');

    return (
        <GlobalROContext.Provider
            value={{
                hereVersion,
                setHereVersion,
                workspace,
                setWorkspace,
                serviceDay,
                setServiceDay,
                week,
                setWeek,
                frequency,
                setFrequency,
                routePlanId,
                setRoutePlanId,
                servicePattern,
                setServicePattern,
                project,
                setProject,
                projectObject,
                setProjectObject,
                siteMode,
                setSiteMode,
                servicePatternView,
                setServicePatternView,
                runningJob,
                setRunningJob,
                globalLoading,
                setGlobalLoading,
                routingProjects,
                setRoutingProjects,
                routingProjectScenarios,
                setRoutingProjectScenarios,
                workspaceStatus,
                setWorkspaceStatus,
                refreshProject,
                setRefreshProject,
                refreshScenario,
                setRefreshScenario,
                refreshRoutePlan,
                setRefreshRoutePlan,
                refreshWorkspaceStatus,
                setRefreshWorkspaceStatus,
                showWorkspaceList,
                setShowWorkspaceList,
                showServiceDayList,
                setShowServiceDayList,
                showRoutePlanList,
                setShowRoutePlanList,
                showWeekList,
                setShowWeekList,
                showFrequencyList,
                setShowFrequencyList,
                showServicePatternList,
                setShowServicePatternList,
                showDownloadWorkspace,
                setShowDownloadWorkspace,
                openAlert,
                setOpenAlert,
                alertTitle,
                setAlertTitle,
                alertMessage,
                setAlertMessage,
                showApiROErrorDialog,
                setShowApiROErrorDialog,
                showROWarningDialog,
                setShowROWarningDialog,
                showContainerDelivery,
                setShowContainerDelivery,
                containerDeliveryEnabled,
                setContainerDeliveryEnabled,
                currencySetting,
                setCurrencySetting,
            }}
        >
            {props.children}
        </GlobalROContext.Provider>
    );
};

export const CustomSelectValue = ({ data }) => (
    <div style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <span title={data.label}>{data.label}</span>
    </div>
);

export const CustomSelectOption = (props) => (
    <components.Option {...props}>
        <span title={props.data.label}>{props.data.label}</span>
    </components.Option>
);

export const SiteAlertDialog = (props) => {
    const { openAlert, alertTitle, alertMessage, setOpenAlert, setAlertTitle, setAlertMessage } = props;
    return (
        <Dialog
            open={openAlert}
            maxWidth={'sm'}
        >
            <DialogTitle>{alertTitle}</DialogTitle>
            <DialogContent>
                {/* <DialogContentText id="map-alert-description"> */}
                {alertMessage && alertMessage.includes('<') && alertMessage.includes('>') ? (
                    <div dangerouslySetInnerHTML={{ __html: alertMessage }} />
                ) : (
                    alertMessage
                )}
                {/* </DialogContentText> */}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => {
                        setOpenAlert(false);
                        setTimeout(() => {
                            setAlertTitle('');
                            setAlertMessage('');
                        }, 1000);
                    }}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ROErrorDialog = () => {
    const { showApiROErrorDialog, setShowApiROErrorDialog } = React.useContext(GlobalROContext);
    return (
        <RODialog
            dialogTitle='Error'
            maxWidth='xs'
            fullWidth
            open={showApiROErrorDialog ? true : false}
            dialogActions={
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ROGreenButton
                        onClick={() => {
                            setShowApiROErrorDialog('');
                        }}
                    >
                        Ok
                    </ROGreenButton>
                </DialogActions>
            }
        >
            {showApiROErrorDialog && showApiROErrorDialog.includes('<') && showApiROErrorDialog.includes('>') ? (
                <div dangerouslySetInnerHTML={{ __html: showApiROErrorDialog }} />
            ) : (
                showApiROErrorDialog
            )}
        </RODialog>
    );
};

export const ROWarningDialog = () => {
    const { showROWarningDialog, setShowROWarningDialog } = React.useContext(GlobalROContext);
    return (
        <RODialog
            dialogTitle=''
            maxWidth='xs'
            fullWidth
            open={showROWarningDialog ? true : false}
            dialogActions={
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ROGreenButton
                        onClick={() => {
                            setShowROWarningDialog('');
                        }}
                    >
                        Ok
                    </ROGreenButton>
                </DialogActions>
            }
        >
            {showROWarningDialog && showROWarningDialog.includes('<') && showROWarningDialog.includes('>') ? (
                <div dangerouslySetInnerHTML={{ __html: showROWarningDialog }} />
            ) : (
                showROWarningDialog
            )}
        </RODialog>
    );
};

export const RODialog = (props) => {
    return (
        <Dialog
            open={props.open}
            maxWidth={props.maxWidth}
            fullWidth={props.fullWidth}
        >
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            {props.dialogContent || ( //for more complex content
                <DialogContent>{props.children}</DialogContent>
            )}
            {props.dialogActions || ( //for more complex action interactions
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ROOrangeButton onClick={props.handleCancel}>{props.cancelText}</ROOrangeButton>
                    <ROGreenButton onClick={props.handleConfirm}>{props.confirmText}</ROGreenButton>
                </DialogActions>
            )}
        </Dialog>
    );
};

export const ROOrangeButton = (props) => {
    return (
        <Button
            {...props}
            style={{ ...props.style, backgroundColor: '#ee8a1d', color: '#fff' }}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
};

export const ROGreenButton = (props) => {
    return (
        <Button
            {...props}
            style={{ ...props.style, backgroundColor: '#16a8a6', color: '#fff' }}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
};

export const RODialogLabel = (props) => {
    return (
        <Typography
            variant='body1'
            gutterBottom
            style={{
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '12px',
            }}
        >
            {props.children}
        </Typography>
    );
};

export const PaperComponent = (props) => {
    return (
        <Draggable
            handle='#draggable-dialog-title'
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
};

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export const IdleTimeOutModal = (props) => {
    const { runningJob } = props;
    const [showModal, setShowModal] = useState(false);
    const [timeLeft, setTimeLeft] = React.useState(30);
    const [intervalId, setIntervalId] = useState(0);

    const onPrompt = () => {
        setTimeLeft(30);
        setShowModal(true);
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(0);
        }
        const newIntervalId = setInterval(() => {
            setTimeLeft((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
        }, 1000);
        setIntervalId(newIntervalId);
    };

    const onAction = (e) => {
        setShowModal(false);
        reset();
    };

    const onActive = (e) => {
        setShowModal(false);
        reset();
    };

    const onIdle = (e) => {
        setShowModal(false);
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(0);
            setTimeLeft(30);
        }
        window.token = '';
        window.ClientID = '';
        window.UserID = '';
        window.sessionStorage.clear();
        window.location.replace(window.location.origin);
    };

    let idelTimeOut = 1000 * 60 * 15;
    if (window.location.origin.includes('localhost') || window.location.origin.includes('192')) {
        idelTimeOut *= 1000;
    }

    React.useEffect(() => {
        if (runningJob) {
            pause();
        } else {
            reset();
        }
    }, [runningJob]);

    const { reset, pause } = useIdleTimer({
        onPrompt,
        onIdle,
        onActive,
        onAction,
        timeout: idelTimeOut,
        promptTimeout: 30 * 1000,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange',
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        syncTimers: 0,
    });

    return (
        <Dialog
            open={showModal}
            maxWidth={'sm'}
        >
            <DialogContent>
                <DialogContentText id='map-alert-description'>
                    The system has been idle.
                    <br />
                    Continued inactivity will result in a session logout in {timeLeft} seconds(s);
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};
