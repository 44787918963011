import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { defaultServiceDayList } from '../../_helpers/common';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getRouteDay } from '../../_actions/MapPage.actions';

export const RouteIdEditor = (props) => {
    const { showRouteIdEditor, handleRouteIdEditorClose, workspace, siteMode, projectObject, serviceDay } = props;
    const [editorServiceDay, setEditorServiceDay] = useState(null);
    const [allRouteList, setAllRouteList] = useState([]);
    const [routeList, setRouteList] = useState([]);
    const [globalRouteOptions, setGlobalRouteOptions] = useState([]);
    const [routeOptions, setRouteOptions] = useState([]);
    const [newRoutes, setNewRoutes] = useState([]);

    useEffect(() => {
        if (showRouteIdEditor) {
            getRouteDay(workspace.value, siteMode, projectObject.ID).then(
                (res) => {
                    setAllRouteList(res.data);
                    setEditorServiceDay(serviceDay);
                },
                (err) => {
                    setAllRouteList([]);
                },
            );
            if (projectObject) {
                const initRoutes = projectObject.InitialRoutes.split(',').map((r) => {
                    return {
                        value: r,
                        label: r,
                    };
                });
                setGlobalRouteOptions(initRoutes);
            }
        } else {
            setEditorServiceDay(null);
        }
    }, [showRouteIdEditor]);

    useEffect(() => {
        if (editorServiceDay) {
            let routes = [];
            if (editorServiceDay.value === 0) {
                routes = [...new Set(allRouteList.map((r) => r.Route))];
            } else {
                routes = [...new Set(allRouteList.filter((r) => r.Day === editorServiceDay.value).map((r) => r.Route))];
            }
            const newRoutes = [],
                newRouteList = [];
            routes.forEach((r, i) => {
                newRoutes[i] = { oldRoute: r, newRoute: r };
                if (globalRouteOptions.filter((g) => g.value === r).length > 0) {
                    newRouteList[i] = [...globalRouteOptions];
                } else {
                    newRouteList[i] = [{ label: r, value: r }, ...globalRouteOptions];
                }
                newRouteList[i] = newRouteList[i].sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));
            });
            setNewRoutes(newRoutes);
            setRouteList(routes);
            setRouteOptions(newRouteList);
        } else {
            setRouteList([]);
            setNewRoutes([]);
            setRouteOptions([]);
        }
    }, [editorServiceDay]);

    const updateRoute = (e, i) => {
        const r = [...newRoutes];
        r[i] = { oldRoute: r[i].oldRoute, newRoute: e.value };
        setNewRoutes(r);
    };

    const handleCreateRoutes = (input, i) => {
        if (!input) {
            return;
        }
        if (/^\w{1,10}$/.test(input)) {
            const newItem = {
                label: input,
                value: input,
            };
            let allOptions = [...routeOptions];
            let options = allOptions[i];

            if (options.filter((l) => l.value === input).length === 0) {
                options.push(newItem);
            }
            options = options.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));
            allOptions[i] = options;

            setRouteOptions(allOptions);

            const r = [...newRoutes];
            r[i] = { oldRoute: r[i].oldRoute, newRoute: input };
            setNewRoutes(r);
        } else {
            return;
        }
    };

    return (
        <Dialog
            open={showRouteIdEditor}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitle>
                Edit Route Ids
                <IconButton
                    aria-label='close'
                    onClick={() => handleRouteIdEditorClose('CANCEL')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid
                    item
                    xs={12}
                >
                    <FormControl
                        style={{ margin: '10px 70px' }}
                        variant='standard'
                    >
                        <FormGroup row>
                            <FormControlLabel
                                value='start'
                                control={<></>}
                                label={<b>Service Day:&nbsp;</b>}
                                labelPlacement='start'
                            />
                        </FormGroup>

                        <div style={{ width: '223px', marginLeft: '18px' }}>
                            <ReactSelect
                                className='basic-single'
                                classNamePrefix='select'
                                isClearable={false}
                                isSearchable={true}
                                isDisabled={projectObject?.ServiceDays !== 'All Days'}
                                name='editorServiceDaySelect'
                                id='editorServiceDaySelect'
                                value={editorServiceDay ? defaultServiceDayList.find((s) => s.value == editorServiceDay.value) : null}
                                onChange={setEditorServiceDay}
                                options={defaultServiceDayList}
                                menuPortalTarget={document.body}
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        padding: 5,
                                    }),
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        </div>

                        <br />
                        <FormGroup row>
                            <FormControlLabel
                                value='start'
                                control={<></>}
                                label={<b>Routes:&nbsp;</b>}
                                labelPlacement='start'
                            />
                        </FormGroup>
                        <Box style={{ maxHeight: '430px', overflowY: 'auto' }}>
                            {routeList.map((r, i) => {
                                return (
                                    <FormGroup
                                        row
                                        style={{ margin: '10px 0' }}
                                    >
                                        <div style={{ width: '223px', marginLeft: '18px' }}>
                                            <CreatableSelect
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={false}
                                                name={`routeSelect_${i}`}
                                                id={`routeSelect_${i}`}
                                                value={routeOptions[i].find((r) => r.value === newRoutes[i].newRoute)}
                                                onChange={(e) => updateRoute(e, i)}
                                                options={routeOptions[i]}
                                                isOptionDisabled={(option) =>
                                                    newRoutes.filter((r) => r !== newRoutes[i].newRoute).includes(option.value)
                                                }
                                                onCreateOption={(e) => handleCreateRoutes(e, i)}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        padding: 5,
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </div>
                                        <FormHelperText style={{ marginLeft: '20px' }}>
                                            Original Route: <b>{r}</b>
                                        </FormHelperText>
                                    </FormGroup>
                                );
                            })}
                        </Box>
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleRouteIdEditorClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleRouteIdEditorClose('SAVE', editorServiceDay.value, newRoutes)}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const RouteIdEditorConfirmDialog = (props) => {
    const { routeIdEditorConfirmDialogOpen, handleRouteIdEditorConfirmDialogClose } = props;

    return (
        <Dialog
            open={routeIdEditorConfirmDialogOpen}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>{'Confirm'}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`Are you sure you want to update Route Ids?`}</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleRouteIdEditorConfirmDialogClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleRouteIdEditorConfirmDialogClose('SAVE')}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
