import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactSelect from 'react-select';
import { defaultServiceDayList } from '../../_helpers/common';
import MaterialReactTable from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getProjectRouteList, refreshServiceLocations } from '../../_actions/MapPage.actions';
import { signalRService } from '../../_helpers/signalr-connection';
import { updateUserActivity } from '../../_actions/Global.actions';

export const RefreshServiceLocationDialog = (props) => {
    const {
        workspace,
        projectObject,
        refreshServiceLocationOpen,
        handleRefreshServiceLocationClose,
        setIsMapLoading,
        showNotificationCallbackDialog,
        showAPICallbackDialog,
    } = props;

    const [refreshStopsRouteSelection, setRefreshStopsRouteSelection] = useState({});
    const [refreshStopsRouteList, setRefreshStopsRouteList] = useState([]);
    const [mode, setMode] = useState(1);

    const modeList = [
        { label: 'Use existing Service Day, Route & Sequence', value: 1 },
        { label: 'Clear Route & Sequence', value: 2 },
    ];

    const allDayModeList = [
        { label: 'Use existing Service Day, Route & Sequence', value: 1 },
        { label: 'Clear Route & Sequence', value: 2 },
        { label: 'Clear Service Day, Route & Sequence', value: 3 },
    ];

    useEffect(() => {
        if (refreshServiceLocationOpen) {
            if (projectObject) {
                let serviceDay = defaultServiceDayList.find((ds) => ds.label === projectObject.ServiceDays);
                getProjectRouteList(workspace.value, serviceDay?.value).then(
                    (res) => {
                        setRefreshStopsRouteList(res.data);
                        setRefreshStopsRouteSelection({});
                    },
                    () => {
                        setRefreshStopsRouteList([]);
                        setRefreshStopsRouteSelection({});
                    },
                );
            } else {
                setRefreshStopsRouteSelection({});
                setRefreshStopsRouteList([]);
            }
        } else {
            setRefreshStopsRouteSelection({});
            setRefreshStopsRouteList([]);
        }
        setMode(1);
    }, [refreshServiceLocationOpen]);

    const handleModeChange = (e) => {
        setMode(e.value);
    };

    const handleRefreshServiceLocations = () => {
        let selectedRoutes = [];
        Object.keys(refreshStopsRouteSelection).map((key) => selectedRoutes.push(key));
        let serviceDay = defaultServiceDayList.find((ds) => ds.label === projectObject.ServiceDays);
        let data = {
            Mode: mode,
            ServiceDay: serviceDay.value,
            Routes: selectedRoutes,
        };

        setIsMapLoading(true);
        refreshServiceLocations(workspace.value, projectObject.ID, data).then(
            (res) => {
                signalRService.startConnection(workspace.value, projectObject.ID);
                signalRService.onReceiveMessage((msg) => {
                    console.log(msg);
                    if (msg) {
                        let message = JSON.parse(msg);
                        if (!message.Disabled) {
                            setIsMapLoading(false);
                            if (message.Errors) {
                                showNotificationCallbackDialog('Error', <div dangerouslySetInnerHTML={{ __html: message.Errors }} />);
                            } else {
                                handleRefreshServiceLocationClose('REFRESH');
                            }
                            signalRService.disconnect();
                        }
                    }
                });
                const logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1222,
                    Mode: 'PLANNER',
                    Success: true,
                    Metadata: projectObject.SandboxName,
                };
                updateUserActivity(logData);
            },
            (err) => {
                setIsMapLoading(false);
                showAPICallbackDialog('Error', err.response.data);
                const logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1222,
                    Mode: 'PLANNER',
                    Success: false,
                    Metadata: projectObject.SandboxName,
                };
                updateUserActivity(logData);
            },
        );
    };

    return (
        <Dialog
            open={refreshServiceLocationOpen}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitle>
                Refresh Service Locations
                <IconButton
                    aria-label='close'
                    onClick={() => handleRefreshServiceLocationClose('CANCEL')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <Grid
                        container
                        item
                        xs={12}
                    >
                        <label style={{ fontSize: '14px', fontWeight: 700, margin: '20px' }}>
                            Service Day: &nbsp;{projectObject ? projectObject.ServiceDays : null}
                        </label>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        style={{ justifyContent: 'center' }}
                    >
                        <div
                            id='RouteSelection'
                            style={{ maxWidth: '100%', padding: '5px', maxHeight: '280px' }}
                        >
                            <MaterialReactTable
                                columns={[{ header: 'Routes', accessorKey: 'Id' }]}
                                data={refreshStopsRouteList}
                                enableTopToolbar={false}
                                enableStickyHeader={true}
                                enableColumnFilters={false}
                                enablePagination={false}
                                enableBottomToolbar={false}
                                enableColumnActions={false}
                                enableFullScreenToggle={false}
                                enableDensityToggle={false}
                                enableHiding={false}
                                enableRowSelection={true}
                                selectAllMode={'all'}
                                getRowId={(originalRow) => originalRow.Id}
                                onRowSelectionChange={setRefreshStopsRouteSelection}
                                initialState={{
                                    rowSelection: {},
                                    density: 'compact',
                                    showGlobalFilter: false,
                                    sorting: [{ id: 'Id', desc: false }],
                                }}
                                state={{
                                    rowSelection: refreshStopsRouteSelection,
                                }}
                                muiTableContainerProps={{
                                    sx: { maxHeight: '260px' },
                                }}
                                muiSelectAllCheckboxProps={{
                                    color: 'default',
                                    title: '',
                                }}
                                muiSelectCheckboxProps={{
                                    color: 'default',
                                    title: '',
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                    >
                        <FormControl
                            style={{ margin: '8px 5px' }}
                            variant='standard'
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <div style={{ width: '270px', marginLeft: '40px' }}>
                                            <ReactSelect
                                                menuPortalTarget={document.body}
                                                placeholder={'None'}
                                                value={
                                                    projectObject?.ServiceDays === 'All Days'
                                                        ? allDayModeList.find((s) => s.value === mode)
                                                        : modeList.find((s) => s.value === mode)
                                                }
                                                onChange={(e) => handleModeChange(e)}
                                                options={projectObject?.ServiceDays === 'All Days' ? allDayModeList : modeList}
                                                styles={{
                                                    menu: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        zIndex: 100,
                                                    }),
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: '10px',
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        padding: 5,
                                                        fontSize: '10px',
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </div>
                                    }
                                    label={<b style={{ fontSize: '12px' }}>For new service location records, would you like to:</b>}
                                    labelPlacement='top'
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={
                        Object.keys(refreshStopsRouteSelection).length === 0
                            ? { backgroundColor: '#fff' }
                            : { backgroundColor: '#16a8a6', color: '#fff' }
                    }
                    disabled={Object.keys(refreshStopsRouteSelection).length === 0}
                    onClick={() => handleRefreshServiceLocations()}
                >
                    Refresh
                </Button>
            </DialogActions>
        </Dialog>
    );
};
