/* eslint eqeqeq: 0 */
export const colors = ['#e6194b', '#3cb44b', '#0082c8', '#f58231', '#911eb4', '#f032e6', '#e6beff', '#aa6e28', '#aaffc3', '#808000'];
export const shapes = ['circle', 'square', 'diamond'];
export const slOutFields = [
    'gid',
    'customer_name',
    'account_id',
    'address',
    'city',
    'state',
    'postal_code',
    'route',
    'demand',
    'service_time',
    'sequence',
    'service_day',
    'service_type',
    'comments',
    'frequency_code',
    'ref_id',
    'workspace_id',
    'side',
    'status',
    'score',
    'quantity',
    'bin_size',
    'earliest_arrival',
    'latest_arrival',
    'no_service_start',
    'no_service_end',
    'week',
    'month',
];
export const facilityOutFields = [
    'facility_id',
    'facility_name',
    'address',
    'city',
    'state',
    'postal_code',
    'link_id',
    'workspace_id',
    'side',
    'status',
    'score',
];

export const regularSymbol = {
    type: 'simple-fill',
    color: [150, 150, 150, 0.35],
    outline: {
        color: [50, 50, 50],
        width: 2,
    },
};

export const editingSymbol = {
    type: 'simple-fill',
    color: [200, 200, 200, 0.85],
    style: 'diagonal-cross',
    outline: {
        color: [50, 50, 50],
        width: 2,
    },
};

export const assignedSymbol = {
    type: 'simple-fill',
    color: [255, 255, 0, 0.35],
    outline: {
        color: [50, 50, 50],
        width: 2,
    },
};

export const firstSymbol = {
    type: 'simple-fill',
    style: 'backward-diagonal',
    color: [0, 255, 0, 0.5],
    outline: {
        color: [50, 50, 50],
        width: 2,
    },
};

export const lastSymbol = {
    type: 'simple-fill',
    style: 'diagonal-cross',
    color: [255, 0, 0, 0.5],
    outline: {
        color: [50, 50, 50],
        width: 2,
    },
};

export const otherRouteSymbol = {
    type: 'simple-fill',
    color: [255, 0, 0, 0.35],
    outline: {
        color: [50, 50, 50],
        width: 2,
    },
};

export const getErrorMessage = (err) => {
    if (err && err.response && err.response.status !== 401 && err.response.data?.Message) {
        let errorMsg = 'We experienced an error.';
        if (typeof err.response.data.Message === 'string') errorMsg = err.response.data.Message;
        return errorMsg;
    }
    return '';
};

export const getFormattedTime = (data) => {
    let hhmm = '00:00';
    if (data) {
        let value = data.toString();
        let a = value.split('.');
        hhmm = a[0].padStart(2, '0');
        if (a.length > 1) {
            let n = Number('.' + a[1]);
            let mins = Math.floor(n * 60);
            hhmm = hhmm + ':' + mins.toString().padStart(2, '0');
        } else {
            hhmm = hhmm + ':00';
        }
    }
    return hhmm;
};

export const getFormattedHHMM = (data) => {
    let hhmm = '00:00';
    if (data) {
        let value = data.split(':');
        let hh = value[0];
        let mm = value[1];
        if (hh.includes('.')) {
            let d = hh.split('.');
            hh = Number(d[0]) * 24 + Number(d[1]);
        }
        hhmm = hh + ':' + mm;
    }
    return hhmm;
};

export const getFormattedTimeSeconds = (data) => {
    let hhmmss = '00:00:00';
    if (data) {
        let decimalTime = Number(data);
        decimalTime = decimalTime * 60 * 60;
        let hours = Math.floor(decimalTime / (60 * 60));
        decimalTime = decimalTime - hours * 60 * 60;
        let minutes = Math.floor(decimalTime / 60);
        decimalTime = decimalTime - minutes * 60;
        let seconds = Math.round(decimalTime);
        if (seconds === 60) {
            seconds = 0;
            minutes += 1;
        }
        if (minutes === 60) {
            minutes = 0;
            hours += 1;
        }
        hhmmss = hours.toString().padStart(2, '0') + ':';
        hhmmss += minutes.toString().padStart(2, '0') + ':';
        hhmmss += seconds.toString().padStart(2, '0');
    }
    return hhmmss;
};

export const formatAMPM = (data) => {
    var timeParts = data.split(':');
    var hours = timeParts[0];
    var minutes = timeParts[1];
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes.padStart(2, '0');
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
};

export const FloatToTimeString = (time) => {
    var int = Math.floor(time);
    var dec = time % 1;
    var n = new Date(0, 0);
    n.setSeconds(+dec * 60 * 60);
    var mm = n.toTimeString().slice(3, 5);
    var hh = int;
    if (int < 10) hh = '0' + int;
    return hh + ':' + mm;
};

export const timeStringToFloat = (time) => {
    let hoursMinutes = time.split(/[.:]/);
    let hours = parseInt(hoursMinutes[0], 10);
    let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
};

export const mmssToFloat = (time) => {
    let minutesSeconds = time.split(/[.:]/);
    let minutes = parseInt(minutesSeconds[0], 10);
    let seconds = minutesSeconds[1] ? parseInt(minutesSeconds[1], 10) : 0;
    return minutes / 60 + seconds / 3600;
};

export const hhmmssToFloat = (time) => {
    let hms = time.split(/[.:]/);
    let hours = parseInt(hms[0], 10);
    let minutes = parseInt(hms[1], 10);
    let seconds = hms[2] ? parseInt(hms[2], 10) : 0;
    return hours + minutes / 60 + seconds / 3600;
};

export function debounce(fn, ms) {
    let timer;
    return (_) => {
        clearTimeout(timer);
        timer = setTimeout((_) => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

export const hexToRGBA = (hex, opacity) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
    }
    throw new Error('Bad Hex');
};

export const compareArray = (a, b) => {
    const aSorted = a.slice().sort();
    const bSorted = b.slice().sort();
    return a.length === b.length && aSorted.every((value, index) => value === bSorted[index]);
};

export const serviceDayList = [
    { value: 'All Days', label: 'All Days', index: -1, serviceDay: 0, display: true },
    { value: 'MON_DRAW', label: 'Monday', index: 1, serviceDay: 1, display: true },
    { value: 'TUE_DRAW', label: 'Tuesday', index: 2, serviceDay: 2, display: true },
    { value: 'WED_DRAW', label: 'Wednesday', index: 3, serviceDay: 3, display: true },
    { value: 'THU_DRAW', label: 'Thursday', index: 4, serviceDay: 4, display: true },
    { value: 'FRI_DRAW', label: 'Friday', index: 5, serviceDay: 5, display: true },
    { value: 'SAT_DRAW', label: 'Saturday', index: 6, serviceDay: 6, display: true },
    { value: 'SUN_DRAW', label: 'Sunday', index: 0, serviceDay: 7, display: true },
];

export const defaultServiceDayList = serviceDayList.map((v) => ({ value: v.serviceDay, label: v.label, display: v.display }));

export const weekList = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
];

export const servicePatternList = [
    { value: '1', label: 'M', icon: '#FF0000' },
    { value: '2', label: 'T', icon: '#00FF00' },
    { value: '3', label: 'W', icon: '#4C7300' },
    { value: '4', label: 'R', icon: '#00FFFF' },
    { value: '5', label: 'F', icon: '#0000FF' },
    { value: '6', label: 'S', icon: '#770081' },
    { value: '7', label: 'U', icon: '#A06632' },
    { value: '0', label: 'No Service Pattern', icon: '#000000' },
];

export const renderServiceWindow = (data) => {
    const EA = timeOfDayOptions_Start.find((e) => e.value == data.EarliestArrival);
    const LA = timeOfDayOptions_End.find((e) => e.value == data.LatestArrival);
    if (data.EarliestArrival > 0 && data.LatestArrival > 0) {
        return EA?.label + ' - ' + LA?.label;
    } else if (data.EarliestArrival > 0) {
        return 'After ' + EA?.label;
    } else if (data.LatestArrival > 0) {
        return 'Before ' + LA?.label;
    } else {
        return '----';
    }
};

export const renderServiceProhibited = (data) => {
    const start = timeOfDayOptions_Prohibited.find((e) => e.value == data.NoServiceStart);
    const end = timeOfDayOptions_Prohibited.find((e) => e.value == data.NoServiceEnd);
    if (data.NoServiceStart > 0 && data.NoServiceEnd > 0) {
        return start?.label + ' - ' + end?.label;
    } else if (data.NoServiceStart > 0) {
        return 'After ' + start?.label;
    } else if (data.NoServiceEnd > 0) {
        return 'Before ' + end?.label;
    } else {
        return '----';
    }
};

export const allTabs = [
    { functionName: 'Map', path: '/map' },
    { functionName: 'Manage', path: '/manage' },
    { functionName: 'Account Management', path: '/account_management' },
    { functionName: 'Administration', path: '/administration' },
];

export const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const timeOfDayOptions_Prohibited = [
    { label: 'None', value: '0.0' },
    { label: 'Midnight', value: '0.01' },
    { label: '12:30 AM', value: '0.5' },
    { label: '01:00 AM', value: '1.0' },
    { label: '01:30 AM', value: '1.5' },
    { label: '02:00 AM', value: '2.0' },
    { label: '02:30 AM', value: '2.5' },
    { label: '03:00 AM', value: '3.0' },
    { label: '03:30 AM', value: '3.5' },
    { label: '04:00 AM', value: '4.0' },
    { label: '04:30 AM', value: '4.5' },
    { label: '05:00 AM', value: '5.0' },
    { label: '05:30 AM', value: '5.5' },
    { label: '06:00 AM', value: '6.0' },
    { label: '06:30 AM', value: '6.5' },
    { label: '07:00 AM', value: '7.0' },
    { label: '07:30 AM', value: '7.5' },
    { label: '08:00 AM', value: '8.0' },
    { label: '08:30 AM', value: '8.5' },
    { label: '09:00 AM', value: '9.0' },
    { label: '09:30 AM', value: '9.5' },
    { label: '10:00 AM', value: '10.0' },
    { label: '10:30 AM', value: '10.5' },
    { label: '11:00 AM', value: '11.0' },
    { label: '11:30 AM', value: '11.5' },
    { label: 'Noon', value: '12.0' },
    { label: '12:30 PM', value: '12.5' },
    { label: '01:00 PM', value: '13.0' },
    { label: '01:30 PM', value: '13.5' },
    { label: '02:00 PM', value: '14.0' },
    { label: '02:30 PM', value: '14.5' },
    { label: '03:00 PM', value: '15.0' },
    { label: '03:30 PM', value: '15.5' },
    { label: '04:00 PM', value: '16.0' },
    { label: '04:30 PM', value: '16.5' },
    { label: '05:00 PM', value: '17.0' },
    { label: '05:30 PM', value: '17.5' },
    { label: '06:00 PM', value: '18.0' },
    { label: '06:30 PM', value: '18.5' },
    { label: '07:00 PM', value: '19.0' },
    { label: '07:30 PM', value: '19.5' },
    { label: '08:00 PM', value: '20.0' },
    { label: '08:30 PM', value: '20.5' },
    { label: '09:00 PM', value: '21.0' },
    { label: '09:30 PM', value: '21.5' },
    { label: '10:00 PM', value: '22.0' },
    { label: '10:30 PM', value: '22.5' },
    { label: '11:00 PM', value: '23.0' },
    { label: '11:30 PM', value: '23.5' },
];

export const timeOfDayOptions_AltFormat = [
    { label: 'Midnight', value: '0' },
    { label: '12:30 AM', value: '0.5' },
    { label: '01:00 AM', value: '1.0' },
    { label: '01:30 AM', value: '1.5' },
    { label: '02:00 AM', value: '2.0' },
    { label: '02:30 AM', value: '2.5' },
    { label: '03:00 AM', value: '3.0' },
    { label: '03:30 AM', value: '3.5' },
    { label: '04:00 AM', value: '4.0' },
    { label: '04:30 AM', value: '4.5' },
    { label: '05:00 AM', value: '5.0' },
    { label: '05:30 AM', value: '5.5' },
    { label: '06:00 AM', value: '6.0' },
    { label: '06:30 AM', value: '6.5' },
    { label: '07:00 AM', value: '7.0' },
    { label: '07:30 AM', value: '7.5' },
    { label: '08:00 AM', value: '8.0' },
    { label: '08:30 AM', value: '8.5' },
    { label: '09:00 AM', value: '9.0' },
    { label: '09:30 AM', value: '9.5' },
    { label: '10:00 AM', value: '10.0' },
    { label: '10:30 AM', value: '10.5' },
    { label: '11:00 AM', value: '11.0' },
    { label: '11:30 AM', value: '11.5' },
    { label: 'Noon', value: '12.0' },
    { label: '12:30 PM', value: '12.5' },
    { label: '01:00 PM', value: '13.0' },
    { label: '01:30 PM', value: '13.5' },
    { label: '02:00 PM', value: '14.0' },
    { label: '02:30 PM', value: '14.5' },
    { label: '03:00 PM', value: '15.0' },
    { label: '03:30 PM', value: '15.5' },
    { label: '04:00 PM', value: '16.0' },
    { label: '04:30 PM', value: '16.5' },
    { label: '05:00 PM', value: '17.0' },
    { label: '05:30 PM', value: '17.5' },
    { label: '06:00 PM', value: '18.0' },
    { label: '06:30 PM', value: '18.5' },
    { label: '07:00 PM', value: '19.0' },
    { label: '07:30 PM', value: '19.5' },
    { label: '08:00 PM', value: '20.0' },
    { label: '08:30 PM', value: '20.5' },
    { label: '09:00 PM', value: '21.0' },
    { label: '09:30 PM', value: '21.5' },
    { label: '10:00 PM', value: '22.0' },
    { label: '10:30 PM', value: '22.5' },
    { label: '11:00 PM', value: '23.0' },
    { label: '11:30 PM', value: '23.5' },
];

export const timeOfDayOptions_Start = [
    { label: 'Route Start', value: '0.0' },
    { label: 'Midnight', value: '0.01' },
    { label: '12:30 AM', value: '0.5' },
    { label: '01:00 AM', value: '1.0' },
    { label: '01:30 AM', value: '1.5' },
    { label: '02:00 AM', value: '2.0' },
    { label: '02:30 AM', value: '2.5' },
    { label: '03:00 AM', value: '3.0' },
    { label: '03:30 AM', value: '3.5' },
    { label: '04:00 AM', value: '4.0' },
    { label: '04:30 AM', value: '4.5' },
    { label: '05:00 AM', value: '5.0' },
    { label: '05:30 AM', value: '5.5' },
    { label: '06:00 AM', value: '6.0' },
    { label: '06:30 AM', value: '6.5' },
    { label: '07:00 AM', value: '7.0' },
    { label: '07:30 AM', value: '7.5' },
    { label: '08:00 AM', value: '8.0' },
    { label: '08:30 AM', value: '8.5' },
    { label: '09:00 AM', value: '9.0' },
    { label: '09:30 AM', value: '9.5' },
    { label: '10:00 AM', value: '10.0' },
    { label: '10:30 AM', value: '10.5' },
    { label: '11:00 AM', value: '11.0' },
    { label: '11:30 AM', value: '11.5' },
    { label: 'Noon', value: '12.0' },
    { label: '12:30 PM', value: '12.5' },
    { label: '01:00 PM', value: '13.0' },
    { label: '01:30 PM', value: '13.5' },
    { label: '02:00 PM', value: '14.0' },
    { label: '02:30 PM', value: '14.5' },
    { label: '03:00 PM', value: '15.0' },
    { label: '03:30 PM', value: '15.5' },
    { label: '04:00 PM', value: '16.0' },
    { label: '04:30 PM', value: '16.5' },
    { label: '05:00 PM', value: '17.0' },
    { label: '05:30 PM', value: '17.5' },
    { label: '06:00 PM', value: '18.0' },
    { label: '06:30 PM', value: '18.5' },
    { label: '07:00 PM', value: '19.0' },
    { label: '07:30 PM', value: '19.5' },
    { label: '08:00 PM', value: '20.0' },
    { label: '08:30 PM', value: '20.5' },
    { label: '09:00 PM', value: '21.0' },
    { label: '09:30 PM', value: '21.5' },
    { label: '10:00 PM', value: '22.0' },
    { label: '10:30 PM', value: '22.5' },
    { label: '11:00 PM', value: '23.0' },
    { label: '11:30 PM', value: '23.5' },
];

export const timeOfDayOptions_End = [
    { label: 'Route End', value: '0.0' },
    { label: '12:30 AM', value: '0.5' },
    { label: '01:00 AM', value: '1.0' },
    { label: '01:30 AM', value: '1.5' },
    { label: '02:00 AM', value: '2.0' },
    { label: '02:30 AM', value: '2.5' },
    { label: '03:00 AM', value: '3.0' },
    { label: '03:30 AM', value: '3.5' },
    { label: '04:00 AM', value: '4.0' },
    { label: '04:30 AM', value: '4.5' },
    { label: '05:00 AM', value: '5.0' },
    { label: '05:30 AM', value: '5.5' },
    { label: '06:00 AM', value: '6.0' },
    { label: '06:30 AM', value: '6.5' },
    { label: '07:00 AM', value: '7.0' },
    { label: '07:30 AM', value: '7.5' },
    { label: '08:00 AM', value: '8.0' },
    { label: '08:30 AM', value: '8.5' },
    { label: '09:00 AM', value: '9.0' },
    { label: '09:30 AM', value: '9.5' },
    { label: '10:00 AM', value: '10.0' },
    { label: '10:30 AM', value: '10.5' },
    { label: '11:00 AM', value: '11.0' },
    { label: '11:30 AM', value: '11.5' },
    { label: 'Noon', value: '12.0' },
    { label: '12:30 PM', value: '12.5' },
    { label: '01:00 PM', value: '13.0' },
    { label: '01:30 PM', value: '13.5' },
    { label: '02:00 PM', value: '14.0' },
    { label: '02:30 PM', value: '14.5' },
    { label: '03:00 PM', value: '15.0' },
    { label: '03:30 PM', value: '15.5' },
    { label: '04:00 PM', value: '16.0' },
    { label: '04:30 PM', value: '16.5' },
    { label: '05:00 PM', value: '17.0' },
    { label: '05:30 PM', value: '17.5' },
    { label: '06:00 PM', value: '18.0' },
    { label: '06:30 PM', value: '18.5' },
    { label: '07:00 PM', value: '19.0' },
    { label: '07:30 PM', value: '19.5' },
    { label: '08:00 PM', value: '20.0' },
    { label: '08:30 PM', value: '20.5' },
    { label: '09:00 PM', value: '21.0' },
    { label: '09:30 PM', value: '21.5' },
    { label: '10:00 PM', value: '22.0' },
    { label: '10:30 PM', value: '22.5' },
    { label: '11:00 PM', value: '23.0' },
    { label: '11:30 PM', value: '23.5' },
    { label: 'Midnight', value: '23.99' },
];

export const startTimeOptions = [
    { label: 'Midnight', value: '00:00' },
    { label: '12:30 AM', value: '00:30' },
    { label: '01:00 AM', value: '01:00' },
    { label: '01:30 AM', value: '01:30' },
    { label: '02:00 AM', value: '02:00' },
    { label: '02:30 AM', value: '02:30' },
    { label: '03:00 AM', value: '03:00' },
    { label: '03:30 AM', value: '03:30' },
    { label: '04:00 AM', value: '04:00' },
    { label: '04:30 AM', value: '04:30' },
    { label: '05:00 AM', value: '05:00' },
    { label: '05:30 AM', value: '05:30' },
    { label: '06:00 AM', value: '06:00' },
    { label: '06:30 AM', value: '06:30' },
    { label: '07:00 AM', value: '07:00' },
    { label: '07:30 AM', value: '07:30' },
    { label: '08:00 AM', value: '08:00' },
    { label: '08:30 AM', value: '08:30' },
    { label: '09:00 AM', value: '09:00' },
    { label: '09:30 AM', value: '09:30' },
    { label: '10:00 AM', value: '10:00' },
    { label: '10:30 AM', value: '10:30' },
    { label: '11:00 AM', value: '11:00' },
    { label: '11:30 AM', value: '11:30' },
    { label: 'Noon', value: '12:00' },
    { label: '12:30 PM', value: '12:30' },
    { label: '01:00 PM', value: '13:00' },
    { label: '01:30 PM', value: '13:30' },
    { label: '02:00 PM', value: '14:00' },
    { label: '02:30 PM', value: '14:30' },
    { label: '03:00 PM', value: '15:00' },
    { label: '03:30 PM', value: '15:30' },
    { label: '04:00 PM', value: '16:00' },
    { label: '04:30 PM', value: '16:30' },
    { label: '05:00 PM', value: '17:00' },
    { label: '05:30 PM', value: '17:30' },
    { label: '06:00 PM', value: '18:00' },
    { label: '06:30 PM', value: '18:30' },
    { label: '07:00 PM', value: '19:00' },
    { label: '07:30 PM', value: '19:30' },
    { label: '08:00 PM', value: '20:00' },
    { label: '08:30 PM', value: '20:30' },
    { label: '09:00 PM', value: '21:00' },
    { label: '09:30 PM', value: '21:30' },
    { label: '10:00 PM', value: '22:00' },
    { label: '10:30 PM', value: '22:30' },
    { label: '11:00 PM', value: '23:00' },
    { label: '11:30 PM', value: '23:30' },
];

export const routeTimeOptions = [
    { label: '30 mins', value: '00:30' },
    { label: '1 hr', value: '01:00' },
    { label: '1 hr 30 mins', value: '01:30' },
    { label: '2 hrs', value: '02:00' },
    { label: '2 hr 30 mins', value: '02:30' },
    { label: '3 hrs', value: '03:00' },
    { label: '3 hr 30 mins', value: '03:30' },
    { label: '4 hrs', value: '04:00' },
    { label: '4 hr 30 mins', value: '04:30' },
    { label: '5 hrs', value: '05:00' },
    { label: '5 hr 30 mins', value: '05:30' },
    { label: '6 hrs', value: '06:00' },
    { label: '6 hr 30 mins', value: '06:30' },
    { label: '7 hrs', value: '07:00' },
    { label: '7 hr 30 mins', value: '07:30' },
    { label: '8 hrs', value: '08:00' },
    { label: '8 hr 30 mins', value: '08:30' },
    { label: '9 hrs', value: '09:00' },
    { label: '9hr 30 mins', value: '09:30' },
    { label: '10 hrs', value: '10:00' },
    { label: '10 hr 30 mins', value: '10:30' },
    { label: '11 hrs', value: '11:00' },
    { label: '11 hr 30 mins', value: '11:30' },
    { label: '12 hrs', value: '12:00' },
    { label: '12 hr 30 mins', value: '12:30' },
    { label: '13 hrs', value: '13:00' },
    { label: '13 hr 30 mins', value: '13:30' },
    { label: '14 hrs', value: '13:00' },
    { label: '14 hr 30 mins', value: '14:30' },
    { label: '15 hrs', value: '15:00' },
];

export const dailyRouteTimeOptions = [
    { label: '30 mins', value: '0.50' },
    { label: '1 hr', value: '1.00' },
    { label: '1 hr 30 mins', value: '1.50' },
    { label: '2 hrs', value: '2.00' },
    { label: '2 hr 30 mins', value: '2.50' },
    { label: '3 hrs', value: '3.00' },
    { label: '3 hr 30 mins', value: '3.50' },
    { label: '4 hrs', value: '4.00' },
    { label: '4 hr 30 mins', value: '4.50' },
    { label: '5 hrs', value: '5.00' },
    { label: '5 hr 30 mins', value: '5.50' },
    { label: '6 hrs', value: '6.00' },
    { label: '6 hr 30 mins', value: '6.50' },
    { label: '7 hrs', value: '7.00' },
    { label: '7 hr 30 mins', value: '7.50' },
    { label: '8 hrs', value: '8.00' },
    { label: '8 hr 30 mins', value: '8.50' },
    { label: '9 hrs', value: '9.00' },
    { label: '9hr 30 mins', value: '9.50' },
    { label: '10 hrs', value: '10.00' },
    { label: '10 hr 30 mins', value: '10.50' },
    { label: '11 hrs', value: '11.00' },
    { label: '11 hr 30 mins', value: '11.50' },
    { label: '12 hrs', value: '12.00' },
    { label: '12 hr 30 mins', value: '12.50' },
    { label: '13 hrs', value: '13.00' },
    { label: '13 hr 30 mins', value: '13.50' },
    { label: '14 hrs', value: '13.00' },
    { label: '14 hr 30 mins', value: '14.50' },
    { label: '15 hrs', value: '15.00' },
];

let arr = [
    { label: '30 mins', value: '00:30' },
    { label: '1 hr', value: '01:00' },
    { label: '1 hr 30 mins', value: '01:30' },
];
for (var i = 2; i < 100; i++) {
    arr.push({ label: i + ' hrs', value: ('0' + i).slice(-2) + ':00' });
    arr.push({ label: i + ' hr 30 mins', value: ('0' + i).slice(-2) + ':30' });
}
export const plannerRouteTimeOptions = [...arr];

export const routeTripOptions = [
    { label: 'None', value: '0' },
    { label: '5 min', value: '300' },
    { label: '10 min', value: '600' },
    { label: '15 min', value: '900' },
    { label: '20 min', value: '1200' },
    { label: '25 min', value: '1500' },
    { label: '30 min', value: '1800' },
];

const times = [
    { label: '12:30 AM', value: '00:30' },
    { label: '01:00 AM', value: '01:00' },
    { label: '01:30 AM', value: '01:30' },
    { label: '02:00 AM', value: '02:00' },
    { label: '02:30 AM', value: '02:30' },
    { label: '03:00 AM', value: '03:00' },
    { label: '03:30 AM', value: '03:30' },
    { label: '04:00 AM', value: '04:00' },
    { label: '04:30 AM', value: '04:30' },
    { label: '05:00 AM', value: '05:00' },
    { label: '05:30 AM', value: '05:30' },
    { label: '06:00 AM', value: '06:00' },
    { label: '06:30 AM', value: '06:30' },
    { label: '07:00 AM', value: '07:00' },
    { label: '07:30 AM', value: '07:30' },
    { label: '08:00 AM', value: '08:00' },
    { label: '08:30 AM', value: '08:30' },
    { label: '09:00 AM', value: '09:00' },
    { label: '09:30 AM', value: '09:30' },
    { label: '10:00 AM', value: '10:00' },
    { label: '10:30 AM', value: '10:30' },
    { label: '11:00 AM', value: '11:00' },
    { label: '11:30 AM', value: '11:30' },
    { label: 'Noon', value: '12:00' },
    { label: '12:30 PM', value: '12:30' },
    { label: '01:00 PM', value: '13:00' },
    { label: '01:30 PM', value: '13:30' },
    { label: '02:00 PM', value: '14:00' },
    { label: '02:30 PM', value: '14:30' },
    { label: '03:00 PM', value: '15:00' },
    { label: '03:30 PM', value: '15:30' },
    { label: '04:00 PM', value: '16:00' },
    { label: '04:30 PM', value: '16:30' },
    { label: '05:00 PM', value: '17:00' },
    { label: '05:30 PM', value: '17:30' },
    { label: '06:00 PM', value: '18:00' },
    { label: '06:30 PM', value: '18:30' },
    { label: '07:00 PM', value: '19:00' },
    { label: '07:30 PM', value: '19:30' },
    { label: '08:00 PM', value: '20:00' },
    { label: '08:30 PM', value: '20:30' },
    { label: '09:00 PM', value: '21:00' },
    { label: '09:30 PM', value: '21:30' },
    { label: '10:00 PM', value: '22:00' },
    { label: '10:30 PM', value: '22:30' },
    { label: '11:00 PM', value: '23:00' },
    { label: '11:30 PM', value: '23:30' },
    { label: 'Midnight', value: '23:99' },
];

export const timeOfDayOptions = [{ label: 'Midnight', value: '00:00' }, ...times];

export const breakStartsOptions = [{ label: 'Route Starts', value: '00:00' }, { label: 'Midnight', value: '00:01' }, ...times];

export const breakEndsOptions = [{ label: 'Route Ends', value: '00:00' }, ...times];

export const facilityTimeOfDayOptions = [{ label: 'Midnight', value: '00:00' }, ...times];

export const breakDurationOptions = [
    { label: '15 min', value: '0.25' },
    { label: '30 min', value: '0.5' },
    { label: '45 min', value: '0.75' },
    { label: '1 hr', value: '1' },
];

export const deFaultServeTimeOptions = [
    { label: '25%', value: '0.25' },
    { label: '50%', value: '0.50' },
    { label: '75%', value: '0.75' },
    { label: '100%', value: '1.00' },
    { label: '125%', value: '1.25' },
    { label: '150%', value: '1.50' },
    { label: '200%', value: '2.00' },
];

export const dailyWorkloadOptions = [
    { label: '10%', value: '0.10' },
    { label: '20%', value: '0.20' },
    { label: '30%', value: '0.30' },
    { label: '40%', value: '0.40' },
    { label: '50%', value: '0.50' },
    { label: '60%', value: '0.60' },
    { label: '70%', value: '0.70' },
    { label: '80%', value: '0.80' },
    { label: '90%', value: '0.90' },
    { label: '100%', value: '1.00' },
    { label: '110%', value: '1.10' },
    { label: '120%', value: '1.20' },
    { label: '130%', value: '1.30' },
    { label: '140%', value: '1.40' },
    { label: '150%', value: '1.50' },
    { label: '160%', value: '1.60' },
    { label: '170%', value: '1.70' },
    { label: '180%', value: '1.80' },
    { label: '190%', value: '1.90' },
    { label: '200%', value: '2.00' },
];

export const ROUTE_SUMMARY_FIELDS = [
    { fieldName: 'Modified', defaultField: true, productivityField: true, costField: true },
    { fieldName: 'Symbol', defaultField: true, productivityField: true, costField: true },
    { fieldName: 'RouteID', defaultField: true, productivityField: true, costField: true },
    { fieldName: 'TotalSvcLocs', defaultField: true, productivityField: true, costField: false },
    { fieldName: 'Quantity', defaultField: true, productivityField: true, costField: false },
    { fieldName: 'Volume', defaultField: true, productivityField: true, costField: false },
    { fieldName: 'Total_Dist', defaultField: true, productivityField: true, costField: false },
    { fieldName: 'NumTrips', defaultField: true, productivityField: true, costField: false },
    { fieldName: 'TotalTimeTS', defaultField: true, productivityField: true, costField: false },
    { fieldName: 'TotalCost', defaultField: true, productivityField: true, costField: true },
    { fieldName: 'Per Hour', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerHourVolume', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerHourQuantity', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerHourStops', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'Per Distance', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerDistanceVolume', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerDistanceQuantity', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerDistanceStops', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'Cost Per', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerCostVolume', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerCostStops', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerCostQuantity', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerCostHour', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'PerCostDistance', defaultField: false, productivityField: true, costField: false },
    { fieldName: 'Crew Cost', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'CrewCostPerHour', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'CrewCostPerHourOT', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'CrewCostPerStop', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'CrewCostPerDemand', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'CrewCostPerDistance', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'CrewCostTotals', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'Vehicle Cost', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'VehCostFixed', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'VehCostHourly', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'VehCostVariable', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'VehCostTotals', defaultField: false, productivityField: false, costField: true },
    { fieldName: 'SeqLoc', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'UnSeqLoc', defaultField: true, productivityField: false, costField: false },
    { fieldName: 'StartLoc', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'StartTime', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'PreStartTm', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'TravelTime', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'ServBase', defaultField: true, productivityField: false, costField: false },
    { fieldName: 'WaitTime', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'ServExtra', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'BreakTime', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'IntTime', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'To_inter', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'From_inter', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'PostEndTm', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'EndTime', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'EndLoc', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'ServDist', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'Start_stem_dist', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'End_stem_dist', defaultField: false, productivityField: false, costField: false },
    { fieldName: 'TotalStemDistance', defaultField: false, productivityField: false, costField: false },
];

export const SCENARIO_ROUTE_SUMMARY_FIELDS = [
    { fieldName: 'RouteId', defaultField: true },
    { fieldName: 'TotalServiceLocations', defaultField: true },
    { fieldName: 'Quantity', defaultField: false },
    { fieldName: 'Demand', defaultField: true },
    { fieldName: 'Distance', defaultField: true },
    { fieldName: 'TotalTime', defaultField: true },
    { fieldName: 'StartTime', defaultField: false },
    { fieldName: 'EndTime', defaultField: false },
    { fieldName: 'ServiceTime', defaultField: false },
    { fieldName: 'StartLocation', defaultField: false },
    { fieldName: 'EndLocation', defaultField: false },
    { fieldName: 'SequencedLocations', defaultField: false },
    { fieldName: 'UnsequencedLocations', defaultField: false },
    { fieldName: 'StartStemDistance', defaultField: false },
    { fieldName: 'EndStemDistance', defaultField: false },
    { fieldName: 'TotalStemDistance', defaultField: false },
];

export const durationOptions = [
    { label: '5 min', value: '00:05' },
    { label: '10 min', value: '00:10' },
    { label: '15 min', value: '00:15' },
    { label: '20 min', value: '00:20' },
    { label: '25 min', value: '00:25' },
    { label: '30 min', value: '00:30' },
    { label: '35 min', value: '00:35' },
    { label: '40 min', value: '00:40' },
    { label: '45 min', value: '00:45' },
    { label: '1 hr', value: '01:00' },
];

export const penaltyOptions = [
    { label: 'None', value: '00:00' },
    { label: '15 min', value: '00:15' },
    { label: '30 min', value: '00:30' },
    { label: '45 min', value: '00:45' },
    { label: '1 hr', value: '01:00' },
    { label: '2 hr', value: '02:00' },
    { label: '3 hr', value: '03:00' },
];

export const currencyMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
};

export const floatMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
};

export const ROUTE_VIEW_TAB_CATEGORIES = [
    { value: 'R/S', label: 'Route/Service Day' },
    { value: 'S/R', label: 'Service Day/Route' },
    { value: 'R', label: 'Routes' }, //new
    { value: 'S', label: 'Service Day' }, //new
];

export const STREET_RENDERER = {
    type: 'simple',
    symbol: {
        type: 'simple-line',
        width: 4,
        color: 'lightgray',
        style: 'solid',
    },
};

export const INVISIBLE_STREET_RENDERER = {
    type: 'simple',
    symbol: {
        type: 'simple-line',
        width: 12,
        color: [255, 255, 0, 0],
        style: 'solid',
    },
};
